import React, { Component } from 'react';
import { userService } from '../services';
import Button from "../components/Button";
import Input from "../components/Input";


class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant: '',
      user:'',
      password:''
    }
    userService.logout();
    this.handleInput = this.handleInput.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState( prevState => { return { [name] : value } });
  }

  render() {
    return (
      <div>
        <form className="container-fluid">
        <Input
            inputtype = { "text" }
            title     = { "Tenant" }
            name      = {"tenant"}
            onChange  = { this.handleInput }
          />
          <Input
            inputtype = { "text" }
            title     = { "User" }
            name      = {"user"}
            onChange  = { this.handleInput }
          />
          <br/>
          <Input
            inputtype ={"password"}
            title     ={"Password"}
            name      = {"password"}
            onChange  = { this.handleInput }
          />
          <br/>
          <Button
            primary={true}
            style={style}
            title={"Log In"}
            type={"primary"}
            action={(event) => this.handleClick(event)}
          />
        </form>
      </div>
    );
  }

  async handleClick(event) {
    event.preventDefault();
    const result = await userService.login({
      tenant  :this.state.tenant,
      user    :this.state.user,
      password:this.state.password
    });

    if(result.error) {
      console.log(result.error) //show error
    }
    else {
      const { from } = this.props.location.state || { from: { pathname: "/settings" } };
      this.props.history.push(from);
    }
    //redirect?
  }
}

const style = {
  margin: "10px 10px 10px 10px"
};

export default LoginContainer;
