import React from "react";

const CheckBox = props => {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="form-label font-weight-bold">
        {props.title}
      </label>
      <div className="checkbox">
        {props.options.map(option => {
          return (
            <React.Fragment>
              <label key={option} className="checkbox-inline">
                <input
                  id={props.name}
                  name={props.name}
                  onChange={props.handlechange}
                  value={option}
                  checked={props.selectedOptions.indexOf(option) > -1}
                  type="checkbox"
                />
                {option}
              </label>
              <br/>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CheckBox;
