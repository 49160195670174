/*eslint eqeqeq: ["off", "smart"]*/
import React, { Component } from "react";
import { NotificationManager } from 'react-notifications';
import $ from 'jquery'
import { userService } from '../services';
import CheckBox from "../components/CheckBox";
import CheckBoxFeatures from "../components/CheckBoxFeatures";
import Input from "../components/Input";
import Button from "../components/Button";
import ParameterOnUrl from "../components/ParameterOnUrl";
import { ApiRequest } from '../services';
import NavigationBar from "./NavigationBar";

let jsonResponse;

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tenant: {
        pageTitle: "",
        logoImgName: "",
        fixedLogoImageName: "",
        baseUrl: "",
        logoImgFile: "",
        embeddedUrl: "",
        features: [],
        callInfoFields: [],
        paramsOnUrl: []
      },
      ref: {
        features: [],
        callInfoFields: []
      },
      featuresListNames: [],
      callInformationFieldsListNames: []
    };



    this.getUrl = this.getUrl.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCreateUrlParameter = this.handleCreateUrlParameter.bind(this);
    this.handleParameterOnUrlChange = this.handleParameterOnUrlChange.bind(this);
    this.removeParameterRow = this.removeParameterRow.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleImageFile = this.handleImageFile.bind(this);
    this.handleCheckBoxCallInfo = this.handleCheckBoxCallInfo.bind(this);
    this.handleCheckBoxFeatures = this.handleCheckBoxFeatures.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  getUrl() {
    return this.prepareEmbeddedUrlAndParamsForJSON.url;
  }

  getFeaturesFromData(features) {
    let featuresEnabled = [];
    features.map(feature => {
      if (feature.enabled) {
        featuresEnabled.push(feature.name);
      }
      this.state.ref.features = features;
      this.state.featuresListNames.push(feature.name);
    });

    return featuresEnabled;
  }

  getCallInfoFieldsFromData(callInfoFields, nonFields) {
    let callInformationList = [];
    let availableFields = [...callInfoFields, ...nonFields || []];
    availableFields.map(callInfo => {
      callInformationList.push(callInfo.name);
    });
    this.state.ref.callInfoFields = availableFields;
    this.state.callInformationFieldsListNames = callInformationList;

    return callInformationList;
  }

  getParamsOnUrlFromData(url, params) {
    let list = [];
    let paramsOnUrl = []
    url.split('&').map(segment => {
      let match = segment.match(/([\S]*?)=rfav1/) || [];
      if (match.length > 1) {
        paramsOnUrl.push(match[1]);
      }
    });
    paramsOnUrl.map((urlParam, i) => list.push({key: urlParam, value: params[i]}));
    this.state.tenant.paramsOnUrl = list;

    return list;
  }

  getBaseUrl(url) {
    let baseUrl = [];
    let decomposedUrl = url.split("&");
    decomposedUrl.map(part => part.indexOf('rfav1') > -1 ? '' : baseUrl.push(part));

    return baseUrl.join('&');
  }

  // Load data from endpoint (tenant config)
  async componentDidMount() {
    try {
      let response = await fetch(ApiRequest.callControlBarDataUrl + `tenantConfig?tenant=${userService.getTenant()}`);
      if (!response.ok) {
        throw Error(response.statusText);
      }
      jsonResponse = await response.json();

      this.setState(
        prevState => ({
          tenant: {
            ...prevState.tenant,
            pageTitle: jsonResponse.customerConfiguration.controlBarTitle,
            fixedLogoImageName: jsonResponse.customerConfiguration.logoPath,
            baseUrl: this.getBaseUrl(jsonResponse.featuresConfiguration.embeddedPage.url),
            embeddedUrl: jsonResponse.featuresConfiguration.embeddedPage.url,
            paramsOnUrl: this.getParamsOnUrlFromData(jsonResponse.featuresConfiguration.embeddedPage.url, jsonResponse.featuresConfiguration.embeddedPage.params),
            features: this.getFeaturesFromData(jsonResponse.features),
            callInfoFields: this.getCallInfoFieldsFromData(jsonResponse.featuresConfiguration.callInformation.fields, jsonResponse.featuresConfiguration.callInformation.nonFields),
            logoFileName: jsonResponse.customerConfiguration.logoPath
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  isFormValid() {
    let errors = [];
    let params = this.state.tenant.paramsOnUrl;
    let emptyParams = params.filter(param => param.key == "" || param.value == "");

    if (emptyParams.length > 0) errors.push("URL Params: The key and value cannot be empty.");

    return errors;
  }

  updateTenant({value, name}) {
    this.setState(
      prevState => ({
        tenant: {
          ...prevState.tenant,
          [name]: value
        }
      })
    );
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.updateTenant({ value, name });
  }

  handleCreateUrlParameter(e) {
    e.preventDefault();
    this.updateTenant({value: [ ...this.state.tenant.paramsOnUrl, {key:"", value:""} ] , name: "paramsOnUrl"});
  }

  handleUrlChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.updateTenant({ value, name });
    $("#embeddedUrl")[0].innerHTML = this.prepareEmbeddedUrlAndParamsForJSON().url;
  }

  handleParameterOnUrlChange(e) {
    e.preventDefault();
    let paramNewAssignement = e.target.value;
    let paramKey = e.target.name;

    this.state.tenant.paramsOnUrl.map(param => {
      if (param.key == paramKey) {
        e.target.attributes['data-url-param'].value == 'url-param-key' ? param.key = paramNewAssignement : param.value = paramNewAssignement;
      }
    });

    this.updateTenant({ value: this.state.tenant.paramsOnUrl, name: "paramsOnUrl" });
    $("#embeddedUrl")[0].innerHTML = this.prepareEmbeddedUrlAndParamsForJSON().url;
  }

  async removeParameterRow(e) {
    e.preventDefault();
    let paramName = e.target.name;
    let remainingParams = this.state.tenant.paramsOnUrl.filter(param => param.key != paramName);

    await this.updateTenant({ value: remainingParams, name: "paramsOnUrl" });
    $("#embeddedUrl")[0].innerHTML = this.prepareEmbeddedUrlAndParamsForJSON().url;
  }

  convertImageToBase64(file) {
    return new Promise((resolve) => {
      const reader = new window.FileReader();

      reader.onload = function(readerEvt) {
          var binaryString = readerEvt.target.result;
          binaryString = btoa(binaryString);
          return resolve(binaryString);
      };

      reader.readAsBinaryString(file);
    });
  }

  async handleImageFile(e) {
    const name = e.target.name;
    const imgFile = e.target.files[0];
    let binaryRepresentation, imageName;
    if (imgFile) {
      binaryRepresentation = await this.convertImageToBase64(imgFile);
      imageName = imgFile.name;
    }
    this.updateTenant({ value: binaryRepresentation, name: 'logoImgFile' })
    this.updateTenant({ value: imageName, name });
  }

  handleCheckBoxCallInfo(e) {
    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.tenant.callInfoFields.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.tenant.callInfoFields.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.tenant.callInfoFields, newSelection];
    }

    this.setState(prevState => ({
      tenant: { ...prevState.tenant, callInfoFields: newSelectionArray }
    }));
  }

  handleCheckBoxFeatures(e) {
    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.tenant.features.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.tenant.features.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.tenant.features, newSelection];
    }

    this.setState(prevState => ({
      tenant: { ...prevState.tenant, features: newSelectionArray }
    }));
  }

  prepareFeaturesForJSON(features) {
    let refFeatures = this.state.ref.features;
    refFeatures.map(refFeature => {
      refFeature.enabled = features.indexOf(refFeature.name) > -1;
    })
    return refFeatures;
  }

  prepareCallInformationForJSON(callInfoFields) {
    let enabled = [];
    let disabled = [];
    let refCallInfoFields = this.state.ref.callInfoFields;
    refCallInfoFields.map(refCallInfo => {
      (callInfoFields.indexOf(refCallInfo.name) > -1) ? enabled.push(refCallInfo) : disabled.push(refCallInfo);
    })

    return {enabled: enabled, disabled: disabled};
  }

  prepareEmbeddedUrlAndParamsForJSON() {
    let url = this.state.tenant.baseUrl;
    let paramsOnUrl = this.state.tenant.paramsOnUrl;
    let paramsValues = [];

    paramsOnUrl.map(param => {
      url += `&${param.key}=rfav1`;
      paramsValues.push(param.value);
    });

    return {url: url, urlParams: paramsValues};
  }

  // On Save
  async handleFormSubmit(e) {
    e.preventDefault();
    let jsonToPush = jsonResponse;
    let tenantData = this.state.tenant;
    let errorsOnForm = this.isFormValid();

    if (!errorsOnForm.length) {
      let featuresForJSON = this.prepareFeaturesForJSON(tenantData.features);
      let callInfoForJSON = this.prepareCallInformationForJSON(tenantData.callInfoFields);
      let embeddedInfoForJSON = this.prepareEmbeddedUrlAndParamsForJSON();

      jsonToPush.customerConfiguration.controlBarTitle = tenantData.pageTitle;
      jsonToPush.customerConfiguration.logoPath = tenantData.fixedLogoImageName;
      jsonToPush.featuresConfiguration.embeddedPage.url = embeddedInfoForJSON.url;
      jsonToPush.featuresConfiguration.embeddedPage.params = embeddedInfoForJSON.urlParams;
      jsonToPush.features = featuresForJSON;
      jsonToPush.featuresConfiguration.callInformation.fields = callInfoForJSON.enabled;
      jsonToPush.featuresConfiguration.callInformation.nonFields = callInfoForJSON.disabled;
      let request = await fetch(ApiRequest.callControlBarDataUrl + "tenantConfig", {
        method: "POST",
        body: JSON.stringify({tenant: userService.getTenant(), config: jsonToPush}, null, 2),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      let response = await request.json();

      if (request.ok) {
        NotificationManager.success(response.response, null, 6000);
      } else {
        NotificationManager.error(response, null, 6000);
      }
    } else {
      errorsOnForm.map(error => NotificationManager.error(error, null, 10000));
    }
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <form className="container-fluid" onSubmit={this.handleFormSubmit}>
        <Input
          inputtype={"text"}
          title={"Page Title"}
          name={"pageTitle"}
          value={this.state.tenant.pageTitle}
          onChange={this.handleInput}
        />
        <Input
          midLabel={this.state.tenant.logoFileName}
          inputtype={"file"}
          title={"Logo img"}
          name={"logoImgName"}
          accept="image/png"
          onChange={this.handleImageFile}
        />
        <Input
          inputtype={"text"}
          title={"Base URL"}
          name={"baseUrl"}
          value={this.state.tenant.baseUrl}
          onChange={this.handleUrlChange}
          onKeyUp={this.handleUrlChange}
        />
        <label id="embeddedUrl">{this.state.tenant.embeddedUrl}</label>
        <div id="params-table">
          <form>
            <ul className="list-group" id="params-list">
              <li className="list-group-item active">
                <div className="row">
                  <label className="col">Parameter</label>
                  <label className="col">Value</label>
                </div>
              </li>
              {this.state.tenant.paramsOnUrl.map(param => {
                return (
                  <ParameterOnUrl
                    label={param.key}
                    value={param.value}
                    onChange={this.handleParameterOnUrlChange}
                    removeAction={this.removeParameterRow}
                  />
                )
              })}
            </ul>
            <Button
              action={this.handleCreateUrlParameter}
              type={"primary"}
              title={"Add Parameter"}
              style={buttonStyle}
            />
          </form>
        </div>
        <br/>
        <div className="row">
          <div className="col">
            <CheckBoxFeatures
              title={"Features"}
              name={"features"}
              options={this.state.featuresListNames}
              selectedOptions={this.state.tenant.features}
              placeholder={"Features"}
              handlechange={this.handleCheckBoxFeatures}
            />
          </div>
          <div className="col">
            <CheckBox
              title={"Call Information Fields"}
              name={"callInfoFields"}
              options={this.state.callInformationFieldsListNames}
              selectedOptions={this.state.tenant.callInfoFields}
              placeholder={"Call Information"}
              handlechange={this.handleCheckBoxCallInfo}
            />
          </div>
        </div>
        <Button
          action={this.handleFormSubmit}
          type={"primary"}
          title={"Submit"}
          style={buttonStyle}
        />
      </form>
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default FormContainer;
