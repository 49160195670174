import userService from './UserService';

export default class ApiRequest {

  static get baseUrl() {
    switch(window.location.hostname) {
      case 'ccbadmin.acd1-stg.smrttouch.com':
        return 'https://ccbapi.acd1-stg.smrttouch.com/api/';
      case 'greenville.nobelbiz.com':
      case 'charlotte.nobelbiz.com':
      case 'greencharter.nobelbiz.com':
      case 'pccw.nobelbiz.com':
        return 'http://ccbapi.cb.nobelbiz.com/api/';
      case 'ccbadmin.infocu5.nobelbiz.com':
        return 'http://ccbapi.infocu5.nobelbiz.com/api/';
      case 'auth.cc.eleadcrm.com':
        return 'http://auth.cc.eleadcrm.com:1331/api/';
      case '10.60.19.151':
        return 'http://10.60.19.151:1331/api/';
      default:
        return 'http://127.0.0.1:4000/api/';
    }
  }

  static get callControlBarDataUrl() {
    return `${ApiRequest.baseUrl}Controlbars/`;
  }

  static get loginUrl() {
    return `${ApiRequest.baseUrl}users/login`;
  }

  static async getConfigurationFromApi() {
    const uri = `${ApiRequest.callControlBarDataUrl}tenantConfig?tenant=${userService.getTenant()}`;
    let jsonResponse = undefined;
    console.log(uri);
    try {
      let response = await fetch(uri);
      if (!response.ok) throw Error(response.statusText);
      jsonResponse = await response.json();
    } catch (error) {
      console.error(error);
    }
    return jsonResponse;
  }

  static async saveConfigurationToApi(config) {
    try {
      await fetch(`${ApiRequest.callControlBarDataUrl}tenantConfig`, {
        method: "POST",
        body: JSON.stringify({
          tenant: userService.getTenant(),
          config
        }, null, 2),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      return [ true ];
    } catch (ex) {
      return [ false, ex ];
    }
  }

}
