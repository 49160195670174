import React from "react";

const ParameterOnUrl = props => {
  return (
    <li class='list-group-item params-row'>
      <div class='row'>
        <input class='col' data-url-param='url-param-key' type='text' name={props.label} value={props.label} onChange={props.onChange} />
        <input class='col' data-url-param='url-param-value' type='text' name={props.label} value={props.value} onChange={props.onChange} />
        <button class='btn btn-danger' name={props.label} onClick={props.removeAction}>Remove</button>
      </div>
    </li>
  );
}

export default ParameterOnUrl;