import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import ApiRequest from './ApiRequest';

const userService = {
  login,
  logout,
  getTenant
};

export default userService;

async function login({ tenant, user, password }) {
    const payload = {
      tenant,
      user,
      password
    }
    const config = {
      headers: { 'Accept': 'application/json' }
    };
    try {
      const result = await axios.post(`${ApiRequest.loginUrl}`, payload, config);
      //const result = {data: {token: "blablabla"}}; // <---- only on localhost when no CM connetion
      console.log("===> ", result);
      localStorage.setItem('ccbar-ui', JSON.stringify({ token: result.data.token, tenant}));
      NotificationManager.success("Welcome!");
      return {};
    }
    catch(e) {
      NotificationManager.error("Credentials error, please verify and try again", null, 5000);
      return { error: e };
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('ccbar-ui');
}

function getTenant() {
  let tenant = localStorage.getItem('ccbar-ui') ? JSON.parse(localStorage.getItem('ccbar-ui')).tenant : "";
  return tenant;
}
