import React from "react";

const Input = props => {
  //console.log(props.value);
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="form-label font-weight-bold">
        {props.title}
      </label>
      {props.midLabel ? <React.Fragment><br/>Default file name: {props.midLabel}</React.Fragment> : ''}
      <input
        autoComplete="off"
        className="form-control"
        id={props.name}
        name={props.name}
        type={props.inputtype}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        {...props}
      />
    </div>
  );
};

export default Input;
