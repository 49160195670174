import React, { Component } from "react";
import { NotificationManager } from 'react-notifications';
import $ from 'jquery'

import DispositionsNavBar from './DispositionsNavBar';
import NavigationBar from "../NavigationBar";
import { ApiRequest } from '../../services';

export default class CustomDispositions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      configurationLoaded: false
    }
  }

  async componentDidMount() {
    const config = await ApiRequest.getConfigurationFromApi();
    if (config) {
      this.setState({
        config             : config,
        configurationLoaded: true,
        dirtyState         : false
      });
    } else {
      NotificationManager.error("Error obtaining the configuration, please contact support.", null, 5000);
    }
  }

  renderCheckboxes() {
    const dispositionGroupNames = Object.keys(this.state.config.dispositions)
    return dispositionGroupNames.map((groupName, id) =>
      <div className="col-xl-6 col-lg-12 text-left text-nowrap" key={id}>
        <label className="checkbox-inline">
          <input type="checkbox" name="campaignFilterGroup" required="required" value={groupName} onClick={e => this.checkboxClicked(e)}/>
          {groupName}
        </label>
      </div>
    )
  }

  renderFilters() {
    return (this.state.config.dispositionsGroupsPerCampaign || []).map((filter, id) =>
      <div className="row" key={`filter_for_${id}`}>
        <div className="col-4 text-left">
          {filter.campaignName}
        </div>
        <div className="col-4">
          <div className="row">
            {this.renderSelectedGroups(filter.campaignName, filter.groups)}
          </div>
        </div>
        <div className="col-4">
          <input data-filter-name={filter.campaignName} type="button" className="btn btn-sm btn-light" onClick={e => this.removeFilter(e, filter.campaignName)} value="🗑️" />
        </div>
      </div>
    )
  }

  renderSelectedGroups(campaignName, groups) {
    return groups.map((groupName, id) =>
      <div key={`${campaignName}_${groupName}_${id}`} className="col-xl-6 col-lg-12 text-left text-nowrap">
        {groupName}
      </div>
    );
  }

  checkboxClicked(e) {
    const allCheckboxes = $('#checkboxGroup input[type="checkbox"]');
    const tickedCheckboxes = $('#checkboxGroup input[type="checkbox"]:checked');
    if (tickedCheckboxes.length) {
      allCheckboxes.removeAttr('required');
    } else {
      allCheckboxes.attr('required', 'required')
    }
  }

  addFilter(e) {
    e.preventDefault();
    const config        = this.state.config;
    const formData      = new FormData(e.target);
    let currentFilters  = config.dispositionsGroupsPerCampaign;
    if (!currentFilters) {
      currentFilters = [];
      config.dispositionsGroupsPerCampaign = currentFilters;
    }
    let campaignFilter = currentFilters.find(filter => filter.campaignName === formData.get('campaignFilterName'));
    if (!campaignFilter) {
      campaignFilter = {campaignName: formData.get('campaignFilterName')};
      currentFilters.push(campaignFilter);
    }
    campaignFilter.groups = formData.getAll('campaignFilterGroup');
    this.setState({
      dirtyState: true,
      config
    });
    e.target.reset();
  }

  removeFilter(e) {
    e.preventDefault();
    const config        = this.state.config;
    let currentFilters  = config.dispositionsGroupsPerCampaign;
    config.dispositionsGroupsPerCampaign = currentFilters.filter(filter => filter.campaignName !== e.target.dataset.filterName);
    this.setState({
      dirtyState: true,
      config
    });
  }

  async preserveState(e) {
    e.preventDefault();
    const [ result, ex ] = await ApiRequest.saveConfigurationToApi(this.state.config);
    if (result) {
      NotificationManager.success('Filters saved', null, 10000);
      this.setState({ dirtyState: false });
    } else {
      NotificationManager.error(`Error saving filters: ${ex}`, null, 10000);
    }
  }

  render() {
    return (
      <div className="card text-center">
        <NavigationBar />
        <DispositionsNavBar />
        <div className="card-body" id="campaignFilters">
          {
            this.state.configurationLoaded ?
            <div>
              <h2>Campaign filters</h2>
              <p className="text-left">This filters will make a certain campaign display only the selected disposition group names.</p>
              {
                this.state.dirtyState &&
                <div className="text-right">
                  <hr />
                  <button type="button" className="btn btn-primary btn-lg" onClick={e => this.preserveState(e)}>Save Modifications</button>
                </div>
              }
              { (this.state.config.dispositionsGroupsPerCampaign || []).length ? <hr /> : ''}
              {this.renderFilters()}
              <hr />
              <p className="text-left">
                To "edit" just enter the name of the campaign again and select a new set of groups.
              </p>
              <form id="categoryForm" onSubmit={e => this.addFilter(e)}>
                <div className="row">
                  <div className="col-4">
                    <input type="text" className="form-control" required="required" name="campaignFilterName" placeholder="Campaign Name"/>
                  </div>
                  <div className="col-4">
                    <div className="row" id="checkboxGroup">
                      {this.renderCheckboxes()}
                    </div>
                  </div>
                  <div className="col-4">
                    <input type="submit" className="btn btn-secondary " value="Add filter"/>
                  </div>
                </div>
              </form>
            </div>
            :
            <h2>Loading data...</h2>
          }
        </div>
      </div>
    );
  }
};
