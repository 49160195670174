import React from "react";
import { MDBTooltip } from 'mdbreact';

const defaultFeatures = {
  webrtc: {description: "Enables WebRTC or SIP keep using extensions. Disabling this option will automatically select SIP method for login, SIP extensions and password are required if disabled", label: "WebRTC"},
  manualCall: {description: "Enables manual calls on the Call Control Bar", label: "Manual Call"},
  transferToAgent: {description: "Enables transfer to agent feature on the Call Control Bar", label: "Transfer to agent"},
  transferToExternal: {description: "The agent can transfer to a number. The agent must type the number", label: "Transfer to external"},
  transferToExternalConference: {description: "Will transfer to a number agent input creating a conference between client, agent and transfered number", label: "Transfer to external conference"},
  transferToQueue: {description: "Transfer the call to a pre-selected queue (please contact support to set the queue to transfer)", label: "Transfer to queue"},
  callInformation: {description: "Shows the lead information during calls on the Call Control Bar", label: "Call information"},
  embeddedPage: {description: "Enables the use of a querystring (Embedded URL + Embedded Params) on the Call Control Bar", label: "Embedded page"},
  notes: {description: "Enables the Notes per leads on the Call Control Bar", label: "Notes"},
  recoverCall: {description: "Not implemented", label: "Recover call"},
  agentNumbersOnly: {description: "Allow numbers only to be input on the agent box login screen", label: "Agent numbers only"}
}

const CheckBoxFeatures = props => {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="form-label font-weight-bold">
        {props.title}
      </label>
      <div className="checkbox">
        {props.options.map(option => {
          return (
            <React.Fragment>
              <MDBTooltip
                placement="right"
                componentClass="checkbox-inline"
                tag="label"
                tooltipContent={option in defaultFeatures ? defaultFeatures[option].description : `There is no description for ${option}`}>
                <input
                  id={option}
                  name={`${option}FeatureId`}
                  onChange={props.handlechange}
                  value={option}
                  checked={props.selectedOptions.indexOf(option) > -1}
                  type="checkbox"
                />
                {option in defaultFeatures ? defaultFeatures[option].label : option}
              </MDBTooltip>
              <br/>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CheckBoxFeatures;
