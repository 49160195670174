/*eslint eqeqeq: ["off", "smart"]*/
import React, { Component } from "react";
import { userService } from '../../services';
import { NotificationManager } from 'react-notifications';
import { ApiRequest } from '../../services';
import NavigationBar from "../NavigationBar";
import DispositionsNavBar from './DispositionsNavBar';
import './CustomDispositions.css'
import $ from 'jquery'

export default class CustomDispositions extends Component {
  parseDispositions() {
    this.jsonResponseFromApi   = this.jsonResponseFromApi || {};
    this.enabledDispositions   = this.jsonResponseFromApi.dispositions || {};
    this.availableDispositions = this.jsonResponseFromApi.availableDispositions || [];
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    this.jsonResponseFromApi.dispositions = this.enabledDispositions;
    this.jsonResponseFromApi.availableDispositions = this.availableDispositions;
    // console.log('updated dispositions:', this.enabledDispositions);
    try {
      await fetch(ApiRequest.callControlBarDataUrl + "tenantConfig", {
        method: "POST",
        body: JSON.stringify({tenant: userService.getTenant(), config: this.jsonResponseFromApi}, null, 2),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      NotificationManager.success('Dispositions were saved!', null, 10000);
    } catch (ex) {
      NotificationManager.error(`Error saving dispositions: ${ex}`, null, 10000);
    }
  }

  deleteDisposition(e) {
    e.preventDefault();
    const disposition     = $(e.target).parent().parent();
    const dispositionCode = disposition.attr('data-code');
    const category        = disposition.parent().attr('data-name');
    this.removeDispositionFromList(category, dispositionCode);
    this.availableDispositions = this.availableDispositions.filter(disposition => disposition.code != dispositionCode);
    disposition.remove();
  }

  renderDisposition(disposition) {
    this.renderedDispositions = this.renderedDispositions || [];
    if (!~this.renderedDispositions.indexOf(disposition.code)) { // only render each box once
      this.renderedDispositions.push(disposition.code);
      const li = $('<li/>')
      .addClass('dispositionBox')
      .append(
        $('<div/>').addClass('name').text(disposition.name),
        $('<div/>').append(
          $('<span/>').addClass('code').text('code: ' + disposition.code),
          $('<span/>').addClass('final').text('is final: ' + (disposition.final || 'false')),
          $('<span/>').addClass('ca').text('ca: ' + (disposition.ca || '-')),
          $('<span/>').addClass('ca').text('retry: ' + (disposition.retry || '-')),
          $('<span/>').addClass('ca').text('is dnc: ' + (disposition.isDnc || 'false')),
          $('<span/>').addClass('remove').text('🗑️').on('click', e => this.deleteDisposition(e))
        )
      )
      .attr('draggable', 'true')
      .attr('data-code', disposition.code);
      return li;
    }
  }

  renderCategory(category, dispositions) {
    const categoryId = `cat${category.replace(/\s/g, '_')}`;
    return $('<li/>').append(
      $('<h4/>').text(category)
        .attr('id', `${categoryId}_title`)
        .attr('data-toggle', 'collapse')
        .attr('data-target', `#${categoryId}`)
        .addClass('category_title'),
      $('<ul/>').append(dispositions.map(disposition => this.renderDisposition(disposition)))
        .attr('id', categoryId)
        .addClass('dropTarget')
        .addClass('collapse')
        .addClass('show')
        .attr('data-name', category)
    );
  }

  async componentDidMount() {
    try {
      console.log(`${ApiRequest.callControlBarDataUrl}tenantConfig?tenant=${userService.getTenant()}`);
      let response = await fetch(`${ApiRequest.callControlBarDataUrl}tenantConfig?tenant=${userService.getTenant()}`);
      if (!response.ok) {
        throw Error(response.statusText);
      }

      this.jsonResponseFromApi = await response.json();
    } catch (error) {
      console.error(error);
    }
    this.parseDispositions();
    // render enabled dispos
    Object.keys(this.enabledDispositions).forEach(key => $('#enabledDispositionsColumn').append(this.renderCategory(key, this.enabledDispositions[key])));
    // render available dispos
    this.availableDispositions.forEach(disposition => $('#availableDispositionsColumn').append(this.renderDisposition(disposition)));
    // drag and drop mambo
    let dragging;
    $(document)
      .on('dragstart', 'ul.dropTarget li', e => {
        dragging = e.target;
        $(e.target).addClass('dragging');
        // $('#dispositionsColumns ul h4:not(.collapsed)').click();
      })
      .on('dragend', 'ul.dropTarget li', e => {
        $(e.target).removeClass('dragging');
      })
      .on('dragover', 'ul.dropTarget', e => {
        e.preventDefault();
      })
      .on('dragenter', 'ul.dropTarget', e => {
        $(e.currentTarget).addClass('hovering');
        e.preventDefault();
      })
      .on('dragleave', 'ul.dropTarget', e => {
        $(e.currentTarget).removeClass('hovering');
        e.preventDefault();
      })
      .on('drop', 'ul.dropTarget', e => {
        const disposition = $(dragging);
        const target = $(e.currentTarget);
        target.removeClass('hovering');
        const fromCategory = disposition.parent().attr('data-name');
        const toCategory = target.attr('data-name');
        const dispositionNumber = disposition.attr('data-code');
        const dispositionObject = this.getDispositionObject(fromCategory, dispositionNumber);
        this.removeDispositionFromList(fromCategory, dispositionNumber);
        this.addDispositionToNewList(toCategory, dispositionObject);
        target.append(dragging);
        this.updateTexts();
      });
    this.updateTexts();
  }

  getDispositionObject(fromCategory, dispositionNumber) {
    let category = fromCategory == 'availableDispositionsColumn' ? this.availableDispositions : this.enabledDispositions[fromCategory];
    return category.find(disposition => disposition.code == dispositionNumber);
  }

  removeDispositionFromList(fromCategory, dispositionNumber) {
    if (fromCategory != 'availableDispositionsColumn') { // only remove from enabled dispositions
      this.enabledDispositions[fromCategory] = this.enabledDispositions[fromCategory].filter(disposition => disposition.code != dispositionNumber);
    }
  }

  addDispositionToNewList(toCategory, dispositionObject) {
    let category = toCategory == 'availableDispositionsColumn' ? this.availableDispositions : this.enabledDispositions[toCategory];
    if (!category.find(disposition => disposition.code == dispositionObject.code)) { // only add if not present
      category.push(dispositionObject);
    }
  }

  addCategory(e) {
    e.preventDefault();
    const newCategoryName = $('#newCategoryName').val();
    if (newCategoryName) {
      this.enabledDispositions[newCategoryName] = [];
      $('#enabledDispositionsColumn').append(this.renderCategory(newCategoryName, []));
      this.updateTexts();
      $('#categoryForm')[0].reset();

      NotificationManager.success("New dispositions category enabled!", null, 5000);
    }
  }

  updateTexts() {
    $('#availableDispositions').val(JSON.stringify(this.availableDispositions));
    $('#enabledDispositions').val(JSON.stringify(this.enabledDispositions));
  }

  createDisposition(e) {
    e.preventDefault();
    this.renderedDispositions = this.renderedDispositions || [];
    if (~this.renderedDispositions.indexOf($('#newDispositionCode').val())) {
      NotificationManager.error('Duplicate code, stopping creation', null, 5000);
      return;
    }
    const newDisposition = {
      name: $('#newDispositionName').val(),
      code: $('#newDispositionCode').val()
    }
    newDisposition.final = $('#newDispositionFinal').val() == "true" ? true : false;
    if ($('#newDispositionRetry').val()) {
      newDisposition.retry = $('#newDispositionRetry').val();
    }
    if ($('#newDispositionCa').val()) {
      newDisposition.ca = $('#newDispositionCa').val();
    }
    newDisposition.isDnc = $('#newDispositionDNC').val() == "true" ? true : false;
    $('#availableDispositionsColumn').append(this.renderDisposition(newDisposition));
    this.availableDispositions.push(newDisposition);
    this.updateTexts();
    $('#createDispositionForm')[0].reset();

    NotificationManager.success("New disposition available!", null, 5000);
  }

  collapseAllCategories(e) {
    e.preventDefault();
    $('.category_title:not(.collapsed)').click();
  }

  expandAllCategories(e) {
    e.preventDefault();
    $('.category_title.collapsed').click();
  }

  render() {
    return (
      <div className="card text-center">
        <NavigationBar />
        <DispositionsNavBar />
        <div className="card-body">
          <div id="dispositions">
            <div id="dispositionsColumns" className="container-fluid">
              <div className="row">

              <div className="column col-xl-6 col-lg-12">
                <h3>Available Dispositions</h3>
                <ul id="availableDispositionsColumn" className="dropTarget" data-name="availableDispositionsColumn">
                </ul>

                <form id="createDispositionForm" onSubmit={e => this.createDisposition(e)}>
                  <div className="row mt-2">
                    <div className="col-8 px-1">
                      <div className="row">
                        <div className="col-4 p-0 pr-1">
                          <input type="text" className="form-control" id="newDispositionName" placeholder="Name" required="required"/>
                        </div>
                        <div className="col-4 p-0 pr-1">
                          <input type="number" size="3" className="form-control " id="newDispositionCode" placeholder="Code" required="required"/>
                        </div>

                        <div className="col-4 p-0 pr-1">
                          <select id="newDispositionFinal" defaultValue="true" className="custom-select">
                            <option value="true" defaultValue="true">is final</option>
                            <option value="false">not final</option>
                          </select>
                        </div>

                      </div>
                      <div className="row mt-1">
                        <div className="col-4 p-0 pr-1">
                          <input type="number" size="3" className="form-control" id="newDispositionRetry" placeholder="Retry Dispo"/>
                        </div>

                        <div className="col-4 p-0 pr-1">
                          <input type="number" className="form-control " id="newDispositionCa" placeholder="CA param"/>
                        </div>

                        <div className="col-4 p-0 pr-1">
                          <select id="newDispositionDNC" defaultValue="false" className="custom-select">
                            <option value="true">is DNC</option>
                            <option value="false" defaultValue="false">not DNC</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 px-1 text-center">
                      <input type="submit" id="addDispositionButton" className="btn btn-secondary mt-3" value="Add disposition"/>
                    </div>
                  </div>
                </form>
              </div>

              <div className="column col-xl-6 col-lg-12">
                <h3 className="mt-xl-0 mt-lg-4">Enabled Dispositions</h3>
                <div className="row">
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-info" onClick={e => this.collapseAllCategories(e)}>Collapse All</button>
                    &nbsp;
                    <button type="button" className="btn btn-info" onClick={e => this.expandAllCategories(e)}>Expand All</button>
                  </div>
                </div>
                <ul id="enabledDispositionsColumn">
                </ul>
                <form id="categoryForm" onSubmit={e => this.addCategory(e)}>
                  <div className="row">
                    <div className="col-8">
                      <input type="text" className="form-control" required="required" id="newCategoryName" placeholder="Campaign Name"/>
                    </div>
                    <div className="col-4">
                      <input type="submit" className="btn btn-secondary " value="Add category"/>
                    </div>
                  </div>
                </form>
              </div>
              </div>
            </div>
            <form className="container-fluid" onSubmit={e => this.handleFormSubmit(e)}>
              <fieldset>
                <legend>this fields will be hidden</legend>
                <input type="text" size="500" id="availableDispositions" defaultValue="" />
                <input type="text" size="500" id="enabledDispositions" defaultValue="" />
              </fieldset>
              <input type="submit" value="Save dispositions" className="btn btn-primary btn-lg mt-1"/>
            </form>
          </div>
          <div className="d-none">
        </div>
        </div>
      </div>
    )
  }
};
