import React, { Component } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import FormContainer from "./containers/FormContainer";
import LoginContainer from "./containers/LoginContainer";
import PrivateRoute  from "./components/PrivateRoute";
import Dispositions from './containers/Dispositions'
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-notifications/lib/notifications.css';
import './index.css'
import "bootstrap/dist/js/bootstrap.min.js";


class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <NotificationContainer/>
          <h1>CALL CONTROLBAR SETTINGS</h1>
          <hr/>
          <div className="app-routes">
            <Switch>
              <Route        path="/login"        component={LoginContainer} />
              <PrivateRoute path="/settings"     component={FormContainer} />
              <PrivateRoute path="/dispositions" component={Dispositions} />
              <Route        path="/"             component={LoginContainer} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

render(<App />, document.getElementById("root"));
