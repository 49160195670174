/*eslint no-unused-vars: ["off", "smart"]*/
// if we remove router the navigation crashes, so remove the warning instead
import React, { Component } from "react";
import { BrowserRouter as Router, NavLink } from 'react-router-dom';

export default class NavigationBar extends Component {
  render() {
    return (
      <div className="card-header">
        <ul className="nav nav-tabs card-header-tabs">
          <li className="nav-item">
            <NavLink to="/dispositions" exact activeClassName="active" className="nav-link">All Dispositions</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/dispositions/campaigns" activeClassName="active" className="nav-link">Campaign filters</NavLink>
          </li>
        </ul>
      </div>
    )
  }
}
