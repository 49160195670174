import CustomDispositions from "./Dispositions/CustomDispositions";
import CampaignFilters from './Dispositions/CampaignFilters';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { Component } from "react";

export default class Dispositions extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/dispositions/campaigns" component={CampaignFilters} />
          <Route path="/"                       component={CustomDispositions} />
        </Switch>
      </Router>
    );
  }
}
