import React, { Component } from "react";
import './NavigationBar.css'

export default class NavigationBar extends Component {
  render() {
    return (
      <div id="nav">
        <ul>
          <li>
            <a href="/settings">Settings</a>
          </li>
          <li>
            <a href="/dispositions">Dispositions</a>
          </li>
          <li className="logout">
            <a href="/login">Log out</a>
          </li>
        </ul>
      </div>
    )
  }
}



